import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import PageWrapper from 'components/layout/PageWrapper';

import CircularProgress from '@mui/material/CircularProgress';
import InnerContentWrapper from '../assessment/components/InnerContentWrapper';
import { appCtx } from '../../components/appStore';
import Layout from '../../components/layout';
import InnerHeader from '../../components/layout/InnerHeader';
import GeneralPageFilter from './components/GeneralPageFilter';
import TopStatisticBar from './components/TopStatisticBar';
import { accountInsightsStoreCtx } from './store';
import styles from './styles';
import DataMetrics from './components/DataMetrics';
import AssessmentsTable from './components/AssessmentsTable';

const DISABLED_TOOLTIP_TEXT = 'You don’t have permission for this action. Please get in touch with the company admin.';

const AccountInsights = observer(({ classes }) => {
    const { loggedUser, company } = useContext(appCtx);
    const { loadData, timeRange, loading, selectedAssessment } = useContext(accountInsightsStoreCtx);
    const { roleType } = loggedUser;

    const isTeamUser = roleType === 'team';

    useEffect(() => {
        loadData();
    }, [timeRange, selectedAssessment]);

    return (
        <Layout>
            <InnerHeader
                header="Account Insights"
                rightPart={(
                    <GeneralPageFilter
                        disabled={isTeamUser}
                        disabledTooltip={DISABLED_TOOLTIP_TEXT}
                    />
                )}
            />
            <PageWrapper className="u-pdn--bx5">
                <InnerContentWrapper
                    className={classes.contentWrapper}
                    contentClassName="u-pdn--x0"
                >
                    {
                        loading
                            ? (
                                <CircularProgress
                                    className={classes.loaderSkill}
                                    size={15}
                                    color="primary"
                                />
                            )
                            : (
                                <>
                                    <TopStatisticBar
                                        company={company}
                                    />
                                    <DataMetrics />
                                    <AssessmentsTable />
                                </>
                            )
                    }

                </InnerContentWrapper>
            </PageWrapper>
        </Layout>
    );
});

export default withStyles(styles)(AccountInsights);
