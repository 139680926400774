import { makeStyles } from '@mui/styles';

const getProgressColor = (theme, value) => {
    if (value >= 80) {
        return theme.palette.green[500]; // High
    }
    if (value >= 40) {
        return theme.palette.yellow[800]; // Medium
    }
    return theme.palette.red[500]; // Low
};

const useStyles = makeStyles(theme => ({
    tabWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    tab: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 10,
        width: '100%',
        height: 78,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.grey[900],
        cursor: 'pointer'
    },
    activeTab: {
        backgroundColor: 'white'
    },
    metricsWrapper: {
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: 'white',
        height: 375,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        padding: 30,
        '& .recharts-responsive-container': {
            transform: 'scaleX(-1) rotate(-90deg)'
        },
        '& .recharts-pie:focus': {
            outline: 'none'
        }
    },
    topSkillTitle: {
        fontSize: 18,
        color: theme.palette.grey[800],
        fontWeight: 700,
        minWidth: 180,
        marginBottom: 16
    },
    circle: {
        minWidth: 18,
        width: 18,
        height: 18,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: 4
    },
    skillRow: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 12,
        color: theme.palette.grey[800],
        fontSize: 14
    },
    divider: {
        width: 1,
        height: '100%',
        backgroundColor: theme.palette.grey[300],
        marginRight: 70,
        marginLeft: 60,
        '@media (max-width: 1230px)': {
            marginRight: 20,
            marginLeft: 20
        }
    },
    skillProgress: {
        width: '50%',
        margin: '0 10px',
        height: 8,
        borderRadius: 56
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: props => getProgressColor(theme, props.value)
    },
    meridianWrapper: {
        width: '55%'
    },
    percentageText: {
        fontSize: 12,
        color: theme.palette.grey[900],
        fontWeight: 700,
        marginLeft: 15
    },
    skillNameMedian: {
        width: 300
    },
    hireChartsWrapper: {
        minWidth: 325,
        display: 'flex',
        flexDirection: 'column',
        '& .recharts-wrapper': {
            transform: 'scaleX(-1) rotate(-90deg)'
        }
    },
    hireStatsWrapper: {
        minWidth: 180,
        gap: 80
    },
    hirePieDescription: {
        fontSize: 16,
        fontWeight: 400,
        marginLeft: 20,
        '& p': {
            margin: 0,
            color: theme.palette.grey[700],
            '& span': {
                fontWeight: 600,
                color: theme.palette.green[600]
            },
            '&.averageDesc': {
                color: theme.palette.grey[600],
                fontSize: 12,
                marginTop: 20,
                '& span': {
                    color: theme.palette.grey[600]
                }
            }
        }
    },
    hireStatBlock: {
        '& p': {
            margin: 0
        },
        '& .average': {
            fontSize: 12,
            color: theme.palette.grey[600],
            '& span': {
                fontWeight: 600
            }
        },
        '& .value': {
            fontSize: 28,
            fontWeight: 700,
            color: theme.palette.green[600]
        }
    },
    statTitle: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    sectionTitle: {
        fontSize: 15,
        color: theme.palette.grey[800],
        fontWeight: 700
    },
    initials: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: 30,
        width: 30,
        background: theme.palette.primary.main,
        color: 'white',
        fontWeight: 700,
        fontSize: 13,
        textTransform: 'uppercase',
        marginRight: 10,
        '&.manager': {
            background: theme.palette.green[600]
        }
    },
    hireFeedback: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.grey[900],
        '& p': {
            margin: 0
        },
        '& .name': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .score': {
            '& span': {
                color: theme.palette.blue[500]
            }
        },
        '& .feedback': {
            fontWeight: 400,
            marginTop: 5
        },
        '& .date': {
            fontSize: 12,
            fontWeight: 400,
            color: theme.palette.grey[500],
            marginTop: 5,
            marginBottom: 15
        },
        '& .hire_again': {
            fontSize: 14,
            fontWeight: 600,
            '& span': {
                textTransform: 'capitalize',
                color: theme.palette.green[600],
                fontWeight: 700
            }
        },
        '&.inmodal': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            paddingBottom: 10,
            marginBottom: 10
        }
    },
    linkClassName: {
        color: theme.palette.blue[500],
        fontWeight: 700,
        fontSize: 12
    },
    csatChartsWrapper: {
        minWidth: 325,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width: 1200px)': {
            minWidth: 250
        },
        '& .recharts-wrapper': {
            transform: 'scaleX(-1) rotate(-90deg)'
        },
        '& .score': {
            fontSize: 28,
            fontWeight: 700,
            color: theme.palette.grey[800]
        }
    },
    csatFeedbacks: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 20,
        position: 'relative',
        '& p': {
            margin: 0
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            height: '100%',
            width: 1,
            backgroundColor: theme.palette.grey[300]
        }
    },
    feedbackWrapper: {
        '&.latest': {
            width: '47%',
            '&:first-child': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            },
            '&:nth-child(2)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`
            }
        },
        '&:not(.latest)': {
            width: '100%',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            paddingBottom: 10,
            marginBottom: 10
        },
        paddingBottom: 10,
        '& .header': {
            fontWeight: 700,
            fontSize: 12,
            '& .score span': {
                color: theme.palette.blue[500]
            }
        },
        '& .comment': {
            fontWeight: 400,
            margin: '10px 0',
            fontSize: 14
        },
        '& .assessment': {
            fontWeight: 700,
            fontSize: 12
        },
        '& .date': {
            color: theme.palette.grey[500],
            marginTop: 10,
            fontSize: 12
        }
    },
    noData: {
        color: theme.palette.grey[700],
        fontSize: 18,
        fontWeight: 700,
        marginTop: 60
    },
    dataUnavailable: {
        display: 'flex',
        alignItems: 'center',
        gap: 7,
        fontSize: 11,
        color: theme.palette.grey[800],
        '& img': {
            width: 20
        }
    },
    noFeedbacks: {
        color: theme.palette.grey[700],
        fontSize: 16,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 16,
        marginTop: 50,
        '& img': {
            width: 40
        }
    },
    readMore: {
        color: theme.palette.blue[500],
        fontWeight: 700,
        fontSize: 14
    }
}));

export default useStyles;
