export default theme => ({
    // Page Itself Styles
    contentWrapper: {
        padding: 0,
        marginBottom: 0,
        backgroundColor: 'transparent',
        border: 'none'
    },
    baseButton: {
        color: theme.palette.blue[500],
        fontWeight: 600,
        fontSize: 12
    },

    // General Page Filter Styles
    assessmentSelect: {
        width: 235
    }
});
