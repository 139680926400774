import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { getCompanyInsights, getSkillsBreakdown, getInsightAuditions, getHireData, getCandidateCsat } from 'requests/AccountInsightsRequests';
import { DATE_RANGES } from 'helper/constants';
import { appStore } from '../../components/appStore';

class AccountInsightsStore {
    @observable dateFilterType = DATE_RANGES.LAST_30_DAYS;

    @observable timeRange = null;

    @observable selectedAssessment = 'all';

    @observable companyInsights = null;

    @observable skillsBreakdown = null;

    @observable hireData = null;

    @observable candidateCsat = null;

    @observable insightAuditions = null;

    @observable assessmentsTablePage = 1;

    @observable loading = false;

    @computed get baseParams() {
        if (!this.timeRange) {
            return {};
        }

        const result = {
            from: this.timeRange[0],
            to: this.timeRange[1]
        };

        if (this.selectedAssessment !== 'all') {
            result.audition = this.selectedAssessment;
        }
        return result;
    }

    @action setTimeRange = (newTimeRange, dateRange) => {
        this.timeRange = newTimeRange;
        this.dateFilterType = dateRange;
    }

    @action setSelectedAssessment = (newSelectedAssessment) => {
        this.selectedAssessment = newSelectedAssessment;
    }

    @action loadData = async () => {
        const { flashMessage } = appStore;
        this.loading = true;
        const params = this.baseParams;
        try {
            const [
                companyInsightsResponse,
                skillsBreakdownResponse,
                hireDataResponse,
                candidateCsatResponse,
                insightAuditionsResponse
            ] = await Promise.all([
                getCompanyInsights(params),
                getSkillsBreakdown(params),
                getHireData(params),
                getCandidateCsat(params),
                getInsightAuditions(params)
            ]);

            if (companyInsightsResponse.success) {
                this.companyInsights = companyInsightsResponse.data;
            }
            if (skillsBreakdownResponse.success) {
                this.skillsBreakdown = skillsBreakdownResponse.data;
            }
            if (hireDataResponse.success) {
                this.hireData = hireDataResponse.data;
            }
            if (candidateCsatResponse.success) {
                this.candidateCsat = candidateCsatResponse.data;
            }
            if (insightAuditionsResponse.success) {
                this.insightAuditions = insightAuditionsResponse.data;
            }
        } catch (error) {
            flashMessage('Something went wrong', 'error');
        } finally {
            this.loading = false;
        }
    }

    @action loadInsightAssessments = async () => {
        const params = this.baseParams;
        const result = await getInsightAuditions({ ...params, page: this.assessmentsTablePage });
        if (result.success) {
            this.insightAuditions = result.data;
        }
    }

    @action handleChangeAssessmentsPage = (page) => {
        this.assessmentsTablePage = page;
        this.loadInsightAssessments();
    };
}

export const accountInsightsStore = new AccountInsightsStore();
export const accountInsightsStoreCtx = createContext(accountInsightsStore);
