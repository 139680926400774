export default theme => ({
    wrapper: {
        gap: 10,
        flexWrap: 'wrap'
    },
    block: {
        backgroundColor: 'white',
        width: 224,
        height: 160,
        padding: '16px 14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.grey[900]
    },
    valueBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 75
    },
    value: {
        fontSize: 28,
        fontWeight: 700,
        color: 'black'
    },
    valueBottom: {
        marginTop: 7,
        height: 20,
        '& img': {
            marginRight: 3,
            marginLeft: -8
        },
        '& span': {
            marginRight: 3
        }
    },
    baseButton: {
        color: theme.palette.blue[500],
        fontWeight: 600,
        fontSize: 16
    },
    green: {
        color: '#00B69B'
    },
    red: {
        color: '#F93C65'
    }
});
