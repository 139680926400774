import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TimeRange from 'components/insight_components/time_range';
import { withStyles } from '@mui/styles';
import { DATE_RANGES } from 'helper/constants';
import Select from 'libraries/Select';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from 'libraries/Tooltip';
import clsx from 'clsx';
import { getAssessmentsList } from 'requests/AccountInsightsRequests';
import { accountInsightsStoreCtx } from '../../store';
import styles from '../../styles';

const GeneralPageFilter = observer(({ classes, disabled, disabledTooltip }) => {
    const [assessment, setAssessment] = useState('all');
    const [options, setOptions] = useState([{ value: 'all', label: 'All Assessments ' }]);

    const { setTimeRange, setSelectedAssessment } = useContext(accountInsightsStoreCtx);

    useEffect(() => {
        getAssessmentsList().then(({ success, data }) => {
            if (success && data) {
                const newOptions = data.map(item => ({ value: item.slug, label: item.name }));
                setOptions(prev => prev.concat(newOptions));
            }
        });
    }, []);

    return (
        <div className="u-dsp--f u-z--10">
            <div className="u-dsp--f u-mrg--rx3">
                <TimeRange
                    handleDateRange={setTimeRange}
                    availableOptions={[DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_30_DAYS, DATE_RANGES.LAST_YEAR]}
                    defaultRange={DATE_RANGES.LAST_30_DAYS}
                />
            </div>

            <Tooltip
                label={disabled ? disabledTooltip : null}
            >
                <div>
                    <Select
                        variant="outlined"
                        margin="none"
                        value={assessment}
                        label="Assessment"
                        className={clsx(classes.assessmentSelect, 'u-mrg--rx3')}
                        onChange={(e) => {
                            setAssessment(e.target.value);
                            setSelectedAssessment(e.target.value);
                        }}
                        options={options}
                        disabled={disabled}
                    />
                </div>
            </Tooltip>
            <ButtonBase
                className={classes.baseButton}
                onClick={() => {
                    // TODO: Implement download PDF
                }}
            >
                Download PDF
            </ButtonBase>
        </div>
    );
});

export default withStyles(styles)(GeneralPageFilter);
