import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: 30,
        backgroundColor: 'white',
        padding: 30
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.grey[900]
    },
    tabWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 16
    },
    tab: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 10,
        width: '100%',
        height: 78,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[200],
        cursor: 'pointer'
    },
    activeTab: {
        backgroundColor: 'white'
    },
    noData: {
        color: theme.palette.grey[700],
        fontSize: 18,
        fontWeight: 700,
        height: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}));

export default useStyles;
